import { get, post, del } from '../helper/API';

export function updateCustomerSubscription(internalPlanId, period, coupon) {
    return post('employer/subscription/subscribe', {
        internalPlanId,
        period,
        coupon
    });
}

export function purchaseProduct(internalPlanId, stripePriceId, coupon) {
    return post('employer/product/purchase', {
        internalPlanId,
        stripePriceId,
        coupon
    });
}

export function subscriptionCancelImmediately() {
    return del('employer/subscription/cancel_immediately');
}

export function retrieveCoupon(coupon) {
    return get('subscription/retrive/coupon', {
        coupon
    });
}

export function updateCreditCard(stripeToken, abn_acn) {
    return post('employer/subscription/customer/update_credit_card', {
        stripeToken,
        abn_acn
    });
}

export function cancelCustomerSubscription(subscription_id, cancel_reason, feedback) {
    return post('subscription/customer/cancel', {
        subscription_id,
        cancel_reason,
        feedback
    });
}

export function getInvoices() {
    return get('employer/invoices');
}

export function getCompanyCompletePackages() {
    return get('employer/company_complete_package_list');
}

export function getCompanyPlan() {
    return get('employer/billing-data');
}

export function getCompanyCards() {
    return get('employer/credit-cards');
}

export function getAvailablePlans() {
    return get('employer/internal-plans/available');
}

export function handleSubmitCard() {
    return get('subscription/stripe/publickey');
}

export function getZapierSettings() {
    return get('employer/zapier');
}

export function updateZapierSettings(data) {
    return post('employer/zapier', data);
}

export function getSmartRecruitersSettings() {
    return get('employer/ats-integrations/smartrecruiters');
}

export function updateSmartRecruitersSettings(enabled, atsCompanyId) {
    return post('employer/ats-integrations/smartrecruiters', { enabled, atsCompanyId });
}

export function getLeverAuthLink() {
    return get('employer/ats-integrations/lever');
}

export function updateLeverSettings(data) {
    return post('employer/ats-integrations/lever', data);
}

export function getLeverPostings() {
    return get('employer/lever/postings');
}

export function getLeverPostingStages(postingId) {
    return get(`employer/lever/postings/${postingId}/stages`);
}

export function getLeverAssessments() {
    return get('employer/lever/auditions');
}

export function linkLeverStageToAssessment(slug, postingId, stageId) {
    return post(`employer/lever/auditions/${slug}/postings/${postingId}/stages/${stageId}`);
}

export function unlinkLeverStageToAssessment(postingId, stageId) {
    return del(`employer/lever/auditions/postings/${postingId}/stages/${stageId}`);
}

export function unauthorizeLever() {
    return del('employer/lever/unauthorize');
}

export function authorizeInLever(data) {
    return post('public/lever/auth', data);
}

export function authorizeInSmartRecruiters(data) {
    return post('public/smartrecruiters/auth', data);
}

export function updateJobAdderSettings(enabled) {
    return post('employer/ats-integrations/job-adder', { enabled });
}

export function getJobAdderSettings() {
    return get('employer/ats-integrations/job-adder');
}

export function authorizeInJobAdder(data) {
    return post('public/job-adder/auth', data);
}

export function deleteJobAdderIntegration() {
    return del('employer/job-adder/unauthorize');
}

export function addAssessmentToJobAdder(slug) {
    return post(`employer/job-adder/auditions/${slug}`);
}

export function deleteAssessmentFromJobAdder(slug) {
    return del(`employer/job-adder/auditions/${slug}`);
}

export function getJobAdderWebhookStatus() {
    return get('employer/job-adder/webhook/status');
}

export function reSubscribeToJobAdderWebhook() {
    return post('employer/job-adder/webhook/re-subscribe');
}

export function deleteAllAssessmentsFromJobAdder() {
    return del('employer/job-adder/auditions');
}

export function getJobAdderCustomFields() {
    return get('employer/job-adder/fields/custom');
}

export function setJobAdderCustomField(customFieldId) {
    return post('employer/ats-integrations/job-adder', { customFieldId });
}

export function pauseSubscription() {
    return post('employer/subscription/pause');
}

export function unpauseSubscription() {
    return post('employer/subscription/unpause');
}

export function prolongPauseSubscription() {
    return post('employer/subscription/prolong-pause');
}

export function updateSmartRecruitersV2(data) {
    return post('employer/ats-integrations/smartrecruiters/v2', data);
}

export function getSmartRecruitersV2Params() {
    return get('employer/ats-integrations/smartrecruiters/v2');
}

export function loginSmartRecruitersV2(companyId) {
    return post('employer/smartrecruiters/auth', { companyId });
}

export function resetSmartRecruitersV2() {
    return del('employer/smartrecruiters/reset');
}

export function getIcimsParams() {
    return get('employer/ats-integrations/icims');
}

export function setIcimsParams(data) {
    return post('employer/ats-integrations/icims', data);
}

export function getIcimsAssessmentList() {
    return get('employer/icims/auditions');
}

export function setIcimsAssessmentId(assessmentSlug, icimsAssessmentNameId) {
    return post(`employer/icims/auditions/${assessmentSlug}`, { icimsAssessmentNameId });
}

export function addAssessmentToJobVite(slug) {
    return post(`employer/job-vite/auditions/${slug}`);
}

export function deleteAssessmentFromJobVite(slug) {
    return del(`employer/job-vite/auditions/${slug}`);
}

export function createIcimStatus(slug, icimsStatusId) {
    return post(`employer/icims/auditions/${slug}/statuses`, { icimsStatusId });
}

export function deleteIcimStatus(slug, icimsStatusId) {
    return del(`employer/icims/auditions/${slug}/statuses`, { icimsStatusId });
}
