import React from 'react';
import { withStyles } from '@mui/styles';
import TextInput from 'libraries/TextInput';
import { setIcimsAssessmentId } from 'requests/SubscriptionRequests';
import StatusesComponent from './StatusesComponent';

const styles = () => ({
    listInput: {
        maxWidth: 250,
        marginBottom: 10,
        marginRight: 20
    }
});

let timerId = null;

const IcimsAssessmentRow = ({ classes, name, slug, icimsAssessmentNameId, icimsStatuses, changeAssessmentID, flashMessage }) => {
    const setIcimsAssessmentNameId = (value) => {
        changeAssessmentID(slug, value);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            setIcimsAssessmentId(slug, value)
                .then(() => flashMessage(`iCims ID was changed to ${value || 'empty value'}`, 'done'))
                .catch(() => flashMessage('Something went wrong', 'error'));
        }, 600);
    };

    return (
        <div className="u-dsp--f u-ai--center">
            <TextInput
                label="Name"
                className={classes.listInput}
                type="text"
                variant="outlined"
                value={name}
                disabled
            />
            <TextInput
                label="Slug"
                className={classes.listInput}
                type="text"
                variant="outlined"
                value={slug}
                disabled
            />
            <TextInput
                label="iCims ID"
                fullWidth
                className={classes.listInput}
                type="text"
                variant="outlined"
                value={icimsAssessmentNameId}
                onChange={e => setIcimsAssessmentNameId(e.target.value)}
            />
            <StatusesComponent
                slug={slug}
                icimsStatuses={icimsStatuses}
            />
        </div>
    );
};

export default withStyles(styles)(IcimsAssessmentRow);
