import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import List from '@mui/material/List';
import TextInput from 'libraries/TextInput';
import Popover from 'libraries/Popover';
import PlusIcon from 'img/plus.svg';
import CloseIcon from 'img/close_darker.svg';
import { createIcimStatus, deleteIcimStatus } from 'requests/SubscriptionRequests';
import { appCtx } from 'components/appStore';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Tooltip from 'libraries/Tooltip';
import useStyles from './styles';

const StatusesComponent = observer(({ icimsStatuses, slug }) => {
    const [loadingStatuses, setLoadingStatuses] = useState(false);
    const [statusToCreate, setStatusToCreate] = useState('');
    const [statusesList, setStatusesList] = useState(icimsStatuses || []);
    const { flashMessage } = useContext(appCtx);

    const { popoverButton, popoverRoot, popoverHeader, popoverContent, loaderWrapper, list, addSection, statusInput, emptyStatuses, statusWrapper, statusesCount } = useStyles();

    const onChange = (e) => {
        setStatusToCreate(e.target.value);
    };

    const addNewStatus = () => {
        setLoadingStatuses(true);
        createIcimStatus(slug, statusToCreate).then(({ success, data }) => {
            if (success) {
                flashMessage(`Status ID ${statusToCreate} added`, 'done');
                setStatusToCreate('');
                setStatusesList(data);
            }
        }).catch((data) => {
            flashMessage(data?.response?.data?.msg || 'Something went wrong', 'error');
        }).finally(() => {
            setLoadingStatuses(false);
        });
    };

    const deleteStatus = (status) => {
        deleteIcimStatus(slug, status).then(({ success, data }) => {
            if (success) {
                setStatusesList(data);
                flashMessage(`Status ID ${status} deleted`, 'done');
            }
        }).catch((data) => {
            flashMessage(data?.response?.data?.msg || 'Something went wrong', 'error');
        });
    };

    return (
        <div className="u-dsp--f u-ai--center">
            <Popover
                className={popoverRoot}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                label={(
                    <Button className={popoverButton}>
                        <img src={PlusIcon} alt="plus" />
                        Status ID
                    </Button>
                )}
            >
                <div className={popoverHeader}>
                    <Typography variant="h6">Status ID</Typography>
                </div>
                <div className={popoverContent}>
                    <List className={list}>
                        {
                            statusesList && statusesList.length ? statusesList.map(status => (
                                <div className={statusWrapper} key={status}>
                                    <TruncateWithTooltip text={status} width={180} />
                                    <img onClick={() => deleteStatus(status)} src={CloseIcon} alt="close" />
                                </div>
                            )) : (
                                <div className={emptyStatuses}>
                                    <Typography variant="h6">
                                        No statuses added
                                    </Typography>
                                </div>
                            )
                        }
                    </List>
                    {
                        loadingStatuses && (
                            <div className={loaderWrapper}>
                                <CircularProgress size={35} color="primary" />
                            </div>
                        )
                    }
                </div>
                <div className={addSection}>
                    <TextInput
                        variant="outlined"
                        placeholder="Create Status ID"
                        className={statusInput}
                        onChange={onChange}
                        value={statusToCreate}
                        maxLength={150}
                    />
                    <Button
                        disabled={loadingStatuses || !statusToCreate}
                        className="u-txt--bold"
                        onClick={addNewStatus}
                        color="primary"
                    >
                        Add
                    </Button>
                </div>
            </Popover>
            <Tooltip label={statusesList.join(', ')}>
                <span className={statusesCount}>{statusesList.length || 0} statuses added</span>
            </Tooltip>
        </div>
    );
});

export default StatusesComponent;
