import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    popoverButton: {
        display: 'flex',
        gap: 10,
        color: theme.palette.blue[500],
        fontWeight: 700,
        fontSize: 14,
        padding: '17px 24px',
        height: 48,
        '& img': {
            width: 14,
            height: 14
        }
    },
    popoverRoot: {
        padding: 0,
        width: 280,
        marginTop: 10
    },
    popoverHeader: {
        padding: '11px 19px 5px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    popoverContent: {
        position: 'relative',
        padding: '15px 2px 12px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    addSection: {
        padding: '10px 19px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    list: {
        maxHeight: 210,
        overflow: 'auto',
        padding: '0 19px'
    },
    emptyStatuses: {
        padding: '15px 0',
        textAlign: 'center'
    },
    statusWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        color: theme.palette.grey[900],
        lineHeight: '34px',
        '& img': {
            cursor: 'pointer',
            width: 14,
            height: 14
        }
    },
    statusesCount: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.grey[500],
        marginLeft: 8,
        minWidth: 115,
        userSelect: 'none'
    }
}));
