import React from 'react';
import { withStyles } from '@mui/styles';

import StarIcon from 'components/icons/Star';
import StarEmptyIcon from 'components/icons/StarEmpty';
import StarHalfIcon from 'components/icons/StarHalf';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        fontSize: 10,
        fontWeight: 600,
        marginLeft: -3,
        color: theme.palette.grey[500],
        '& svg:last-child': {
            marginRight: '5px'
        }
    }
});

const Rating = ({ classes, csatScore, csatNumberOfFeedbacks }) => {
    if (!csatScore || csatScore < 3) {
        return (
            <div className={classes.wrapper}>
                {[...Array(5)].map(() => <StarEmptyIcon size={20} color="#C7C8CC" />)}
                No Ratings
            </div>
        );
    }
    const countFullStars = Math.floor(Math.round(csatScore * 2) / 2);
    const coundHalfStars = csatScore > countFullStars ? 1 : 0;
    const difference = 5 - coundHalfStars - countFullStars;
    const coundEmptyStars = difference > 0 ? difference : 0;
    return (
        <div className={classes.wrapper}>
            {[...Array(countFullStars)].map(() => <StarIcon size={20} color="#FFD04C" />)}
            {[...Array(coundHalfStars)].map(() => <StarHalfIcon size={20} color="#FFD04C" />)}
            {[...Array(coundEmptyStars)].map(() => <StarEmptyIcon size={20} color="#FFD04C" />)}
            {csatNumberOfFeedbacks + 100} Ratings
        </div>
    );
};

export default withStyles(styles)(Rating);
